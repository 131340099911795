@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


body {
  margin: 0;
  overflow: hidden;
}

.iframe-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.toggle-chatbot-btn {
  width: 55px;
  height: 55px;
  position: fixed;
  bottom: 10px;
  right: 28px;
  color: #fff;
  border: none;
  border-radius: 55px;
  cursor: pointer;
  background-color: white;
  padding: 5px;
  object-fit: cover;
}

.chatbot-container {
  position: fixed;
  bottom: 20px;
  /* Adjust as needed */
  right: 20px;
  /* Adjust as needed */
}

.chatbot-container {
  position: fixed;
  bottom: -400px;
  /* Initially off-screen */
  width: 400px;
  /* max-height: 600px; */
  /* border: 1px solid #ccc; */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: #fff;
  transition: bottom 0.5s ease-in-out;
}

.chatbot-container.show {
  right: 20px;
  bottom: 0px;
}


.chatbot-header {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: #009933;
}
.chatbot-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
}

.chatbot-buttons img {
  cursor: pointer;
}

.hidebutton {
  display: none;
}

.chatbot-messages {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  display: flex;
  flex-direction: column;
  height: 400px;
  overflow-y: auto;
  padding: 10px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}


.user-message-container {
  display: flex;
  justify-content: flex-end;
}
.bot-message-container {
  display: flex;
  justify-content: flex-start;
  
}

/* .user-message-container p, .bot-message-container p{
  margin-top: 0px !important;
  margin-bottom: 0px !important;
} */

.user-message {
  background-color: #e6e5ec;
  color: #000;
  margin-bottom: 10px;
  padding: 3px 10px;
  border-radius: 10px;
  text-align: left;
  font-size: 13px;
  max-width: 80%;
}

.bot-message {
  font-size: 13px;
  background-color: #e6e5ec;
  color: #000;
  margin-bottom: 10px;
  padding: 3px 10px;
  border-radius: 10px;
  max-width: 88%;
  width: auto;
  display: inline-block;
}


.form-container {
  display: flex;
  align-items: center;
  padding: 10px;
}

.input-container {
  flex: 1;
}

.input-container {
  display: flex;
  justify-content: space-between;
  border: 1px solid #9d9d9d;
  background-color: #fbfbfb;
  border-radius: 30px;
  padding: 7px;
}

.input-container input {
  width: 100%;
  font-size: 13px;
  background-color: #fbfbfb;
  border-radius: 30px;
  padding: 10px;
  border: none;
}

.input-container input:focus {
  outline: none;
  /* Remove the default focus outline */
}

.input-container textarea {
  width: 100%;
  font-size: 13px;
  background-color: #fbfbfb;
  border-radius: 30px;
  padding: 10px;
  border: none;
  outline: none;
  padding: 5px 5px 5px 5px;
  width: 100%;
}

.input-container textarea:focus {
  outline: none;
  /* Remove the default focus outline */
}

.input-container button {
  background: transparent;
  border: none;
  color: black;
  cursor: pointer;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.spinner-icon {
  animation: spin 1s linear infinite;
  /* Apply animation to the spinner icon */
}

.loader-text {
  color: #4a4a4a;
  font-size: 12px;
  margin-top: 10px;
  overflow: hidden;
  white-space: nowrap;
}

.loader-text span {
  display: inline-block;
  animation: moveText 8s linear infinite;
  color: #4a4a4a;
  font-size: 8px;
}

@keyframes moveText {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}

.loader {
  width: 30px;
  aspect-ratio: 2;
  /* --_g: no-repeat radial-gradient(circle closest-side,#070046 90%,#0000); */
  background: 
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 50%;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
    20%{background-position:0%   0%, 50%  50%,100%  50%}
    40%{background-position:0% 100%, 50%   0%,100%  50%}
    60%{background-position:0%  50%, 50% 100%,100%   0%}
    80%{background-position:0%  50%, 50%  50%,100% 100%}
}

@media screen and (max-width: 768px) {

  .chatbot-container {
    width: 95%;
  }
 }

 .math-jax-container span {
  white-space: nowrap;
  overflow: inherit;
}

.markdown {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: normal;
}

.markdown h1 {
  margin: 12px 0px !important;
  font-size: 24px !important;
}

.markdown h2 {
  margin: 12px 0px !important;
  font-size: 22px !important;
}

.markdown h3 {
  font-size: 20px !important;
  margin-bottom: 4px;
  margin: 12px 0px !important;
}

.markdown h4 {
  margin: 12px 0px !important;
  font-size: 18px !important;
}

.markdown h5 {
  margin: 12px 0px !important;
  font-size: 16px !important;
}

.markdown ol,
.markdown ul {
  white-space: initial;
  line-height: 1.6rem;
}

.markdown table {
  border-collapse: collapse;
  margin: 10px 0px;
}

.markdown th,
.markdown td {
  text-align: left;
  padding: 6px 13px;
  border: 1px solid black;
}

.markdown li {
  margin: 10px 0px;
  white-space: normal;
  text-align: start;
}

.markdown li p {
  margin: 0;
  padding: 0;
  display: inline;
}

.markdown li b,
.markdown li strong {
  font-weight: 500;
}



.markdown p {
  white-space: normal;
  margin: 10px 0px;
  padding: 0;
  line-height: 1.6rem !important;
}

.markdown span {
  overflow: inherit !important;
  text-overflow: inherit !important;
  white-space: pre !important;
  line-height: 1.6;
}